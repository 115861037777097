
@media (max-device-width: 399px) {
    .grav-dashboard {
        height: 300px;
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 400px) and (max-width: 1599px) {
    .grav-dashboard {
        width: 100%;
        height: 500px;
        margin: 5px;
        flex: 0 0 auto;
    }
}

@media (min-width: 1000px) {
    .grav-dashboard {
        width: 45%;
        height: 500px;
        margin: 5px;
        flex: 0 0 auto;
    }
}